<template>
  <div class="wrapper padding-lr-sm">
    <div class="flex align-center justify-between margin-bottom-sm">

      <div>
        <el-radio-group v-model="type" size="small" @change="hanldSearch">
          <el-radio-button :label="0">全部员工</el-radio-button>
          <el-radio-button :label="1">全职员工</el-radio-button>
          <el-radio-button :label="2">实习生</el-radio-button>
          <el-radio-button :label="3">兼职</el-radio-button>
        </el-radio-group>
      </div>

      <div class="flex align-center justify-end flex-sub">
        <el-radio-group v-model="lizhiType" size="small" @change="hanldSearch" class="margin-right-sm">
          <el-radio-button :label="2">离职办理中</el-radio-button>
          <el-radio-button :label="3">已离职</el-radio-button>
        </el-radio-group>
        <el-input size="small" v-model="keyword" placeholder="请输入员工名称" class=" margin-right-sm top-search-input"></el-input>
        <el-button size="small" type="primary" @click="hanldSearch">搜索</el-button>
      </div>

    </div>
    <el-table
        :data="staffList"
        header-row-class-name="table-header"
        row-class-name="table-row"
        alig="center"
        :cell-style="{'text-align':'center'}"
        border
        style="width: 100%"
    >
      <el-table-column width="70" type="index" align="center" label="序号"></el-table-column>
      <el-table-column label="类型" prop="leixing">
        <template slot-scope="scope">
          {{ scope.row.leixing === 1 ? '全职' : (scope.row.leixing === 2 ? '实习生' : '兼职') }}
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="zaizhilizhi">
        <template slot-scope="scope">
          {{ scope.row.zaizhilizhi === 1 ? '在职' : scope.row.zaizhilizhi === 2 ? '离职办理中' : '离职' }}
        </template>
      </el-table-column>
      <el-table-column label="姓名" prop="xingming"></el-table-column>
      <el-table-column label="英文/昵称" prop="nicheng"></el-table-column>
      <el-table-column label="组织/部门" prop="bumen"></el-table-column>
      <el-table-column label="岗位" prop="gangwei"></el-table-column>
      <el-table-column label="合同到期时间" prop="hetongdaoqishijian"></el-table-column>
      <el-table-column label="实际离职时间" prop="shijilizhishijian"></el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <div class="table-operate">
            <div class="flex align-center justify-center margin-bottom-xs">
              <el-button v-if="scope.row.zaizhilizhi === 2" size="small" type="primary" plain @click="departItem(scope.row.offer_id,scope.row.leixing)">离职办理中</el-button>
              <el-button v-if="scope.row.zaizhilizhi === 3" size="small" type="primary" plain @click="departItem(scope.row.offer_id,scope.row.leixing)">已离职</el-button>
            </div>

          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        v-if="staffList.length > 0"
        class="page-all"
        background
        @current-change="getList"
        :current-page.sync="page"
        :page-size="limit"
        layout="prev, pager, next, jumper"
        :total="total"
    >
    </el-pagination>

  </div>
</template>

<script>
import {staffList} from "@/api/staff";

export default {
  name: "OrgList",
  data() {
    return {
      keyword: '',
      lizhiType: 2,
      type: 0,
      page: 1,
      limit: 10,
      total: 0,
      staffList: [],
    }
  },
  created() {
    this.$emit("setIndex", this.$route.path, "员工离职", false);
    this.getList()
  },
  components: {},
  methods: {
    departItem(id,type){
      if (type === 1) this.$router.push(`fullTime/info/${id}`)
      if (type === 2) this.$router.push(`practiceTime/info/${id}`)
      if (type === 3) this.$router.push(`partTime/info/${id}`)
    },
    //  搜索
    hanldSearch() {
      this.page = 1
      this.getList()
    },
    //  获取员工列表
    async getList() {
      const res = await staffList(this.page, this.limit, this.keyword, this.type, this.lizhiType)
      this.staffList = res.data
      this.total = res.total_number
    }
  },
  filters: {},
  watch: {},
}
</script>

<style scoped lang="scss">

</style>
